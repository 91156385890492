import axios from "@axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchEmployeeLeaves(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/employee-leaves/"+queryParams.hashid,{params:{year:queryParams.year,leave_type:queryParams.leave_type}})
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchEmployeeLeave(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`employee-leaves/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        addEmployeeLeave(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("employee-leaves", userData)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        updateEmployeeLeave(ctx, employee_leave) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`employee-leaves/${employee_leave.id}`, employee_leave.data, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        removeEmployeeLeave(ctx, userId) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`employee-leaves/${userId}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        removeMultipleEmployeeLeaves(ctx, ids) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/deleteMultipleEmployeeLeaves/`, { ids: ids })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        getCustomFields() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/employee-leaves/create")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        updateEmployeeLeaveBalance(ctx, data) {
            return new Promise((resolve, reject) => {
              axios
                .patch('/employee-leaves-update', data, {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                })
                .then(response => {
                  resolve(response);
                })
                .catch(error => {
                  if (error.response && error.response.status === 422) {
                    if (error.response.data.errors) {
                      const errors = error.response.data.errors;
                      const firstErrorKey = Object.keys(errors)[0];
                      const firstErrorMessage = errors[firstErrorKey][0];
                      reject(firstErrorMessage);
                    }
                  } else {
                    reject(error.message);
                  }
                });
            });
          },
          
        import(ctx, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/"+data.url,data.data, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
    }
};
